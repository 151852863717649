// @noflow
import React from 'react';
import { ConfettiFramedContainer } from '@latitude/confetti-framed-container';
import EstimateRateWidget from './EstimateRateWidget';
import { EstimateRateWidgetSectionWrapper } from './EstimateRateWidget.styled';

interface Props {
  purpose?: string;
  loanAmount?: number;
  repaymentPeriod?: number;
  purposeOther?: string;
  trackProductId?: Array<string>;
}

const EstimateRateWidgetSection = (props: Props) => (
  <EstimateRateWidgetSectionWrapper id="check-your-rate">
    <ConfettiFramedContainer>
      <EstimateRateWidget
        defaultPurpose={props.purpose}
        defaultPurposeOther={props.purposeOther}
        defaultLoanAmount={props.loanAmount}
        defaultRepaymentPeriod={props.repaymentPeriod}
        trackProductId={props.trackProductId}
      />
    </ConfettiFramedContainer>
  </EstimateRateWidgetSectionWrapper>
  );

export default EstimateRateWidgetSection;
