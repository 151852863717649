// @flow

import * as React from 'react';
import styled from 'styled-components';
import Section from '@latitude/section';
import { Heading3 } from '@latitude/heading';
import { BREAKPOINT, COLOR, PADDING } from '@latitude/core/utils/constants';
import { RatesBox } from '@latitude/rates-box';
import FeesAndCharges from './FeesAndCharges';
import Modal from '../Modal/Modal';
import loansData from '../../data/pages/loans.json';
import ratesData from '../../data/json/Rates/rates.json';
import './_loan-rates.scss';
import PersonalLoanContract from '../PersonalLoanContract/PersonalLoanContract';

const securedUnsecuredText = {
  title: 'Secured vs Unsecured loan?',
  content:
    'When shopping around or applying for a personal loan, one decision you’ll need to make is whether you want a secured or unsecured loan. Taking out a secured loan means you’re able to provide an asset like your car, for the lender to secure your loan against. An advantage of taking out a secured loan is that you may be eligible for a lower interest rate. If you don’t have a suitable asset to secure your loan against, you may decide to apply for an unsecured loan.'
};

type Props = {
  fees: [],
  className?: string,
  isBranded?: boolean,
  rateBoxType?: string,
  rateBoxType2?: string,
  padBottom?: string
};

type State = {
  isModalOpen: boolean
};

class LoanRates extends React.Component<Props, State> {
  state = {
    isModalOpen: false
  };

  handleModalRequestClose = () => {
    this.setState({ isModalOpen: false });
  };

  handleLinkClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    this.setState({
      isModalOpen: true
    });
  };

  render() {
    const rateBoxType =
      this.props.rateBoxType &&
      this.props.rateBoxType.length > 0 &&
      this.props.rateBoxType.split(',');

    const rateBoxType2 =
      this.props.rateBoxType2 &&
      this.props.rateBoxType2.length > 0 &&
      this.props.rateBoxType2.split(',');
    return (
      <Section id="rates" className="rates-fees-charges__section">
        <div className="row">
          <div
            className={`col-12 text-center ${this.props.padBottom || ''} ${
              this.props.isBranded ? 'col-lg-5' : 'col-md-5'
            } `}
            style={{ marginBottom: '30px' }}
          >
            <Heading3>Rates</Heading3>
            <div style={{ maxWidth: '350px', margin: '0 auto' }}>
              {rateBoxType &&
                rateBoxType.map((rateType: string, index: number) => (
                  <RatesBox
                    isBranded
                    theme="primary"
                    key={index}
                    type={rateType}
                    fullWidth
                    data={ratesData}
                    size="small"
                  />
                ))}
              {rateBoxType2 &&
                rateBoxType2.map((rateType2: string, index: number) => (
                  <RatesBox
                    isBranded={this.props.isBranded}
                    darkBlue
                    theme="secondary"
                    key={index}
                    type={rateType2}
                    fullWidth
                    data={ratesData}
                    size="small"
                  />
                ))}
            </div>
            {this.props.hideInfoModal ? null : (
              <div
                className="loan-type"
                css={`
                  @media (max-width: ${BREAKPOINT.MD}) {
                    padding-bottom: ${PADDING.P24};
                  }
                `}
              >
                <svg
                  height="16px"
                  width="16px"
                  className="exclamation"
                  role="img"
                >
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/media/nz/icons-sprite.svg#info-block"
                  />
                </svg>

                <a
                  onClick={this.handleLinkClick}
                  role="presentation"
                  className="loan-type-link"
                >
                  Secured vs Unsecured loan?
                </a>
              </div>
            )}
            <div css="max-width: 350px;">
              <PersonalLoanContract />
            </div>
          </div>
          <div className={`${this.props.isBranded ? 'col-lg-7' : 'col-md-7'}`}>
            <FeesAndCharges isBranded={this.props.isBranded} {...this.props} />
          </div>
          <Modal
            isOpen={this.state.isModalOpen}
            onRequestClose={this.handleModalRequestClose}
            title={loansData.securedUnsecuredText.title}
            content={loansData.securedUnsecuredText.content}
            ariaHideApp={this.state.isModalOpen}
          />
        </div>
      </Section>
    );
  }
}

const LoanRatesBranded = styled(LoanRates)``;

const LoanRatesContainer = styled.div`
  section.rates-fees-charges__section {
    background-color: transparent;
  }
  .section.rates-fees-charges__section h3 {
    font-size: 24px;
    text-align: left;
    margin: 0 0 24px;
    color: ${COLOR.BLACK};
  }

  h3 + div {
    margin-left: 0 !important;
  }

  .loan-type {
    text-align: left;
    > a.loan-type-link[role='presentation'] {
      font-size: 14px;
      color: ${COLOR.BLACK} !important;
      border-bottom: 0 !important;
      text-decoration: underline !important;
    }
  }

  .modal__title {
    border: 1px solid red;
  }

  .fees-charges__table {
    background-color: ${COLOR.WHITE};
    border: solid 1px #e5e5e5;
    font-weight: 300;
    overflow: hidden;
    color: ${COLOR.GREY75};

    hr.table__rule {
      height: 1px;
      background-color: #e5e5e5;
      margin: 0 20px 12px 20px;
      border: 0;
    }

    .table__cell {
      padding: 0 24px 12px 24px;

      &.font-weight-bold {
        font-weight: normal !important;
      }
    }

    .table__row {
      &:first-child,
      &:last-child {
        hr {
          margin: 0 0 24px;
          background-color: transparent;
        }
      }
      &:last-child {
        hr {
          margin: 0 0 12px;
        }
      }
    }
    .table__row--border-bottom {
      .table__cell {
        padding-bottom: 12px;
      }
    }
    .table__row--border-bottom {
      border: 0;
    }
  }

  p.small-text {
    padding: 12px 0;
    border-top: 0;

    &:empty {
      display: none;
    }
  }
`;

export default (props: Props) =>
  !props.isBranded ? (
    <LoanRates {...props} />
  ) : (
    <LoanRatesContainer>
      <LoanRatesBranded {...props} />
    </LoanRatesContainer>
  );


