import React from 'react';
import HowToApply from '@latitude/how-to-apply';
import { Heading4 } from '@latitude/heading';
import { COLOR, MARGIN } from '@latitude/core/utils/constants';
import { ListNumbered } from '@latitude/list-numbered';
import { List, ListItem } from '@latitude/list';
import { Strong } from '@latitude/text';
import data from '../../data/json/how-to-apply.json';

const HowToApplyEligibility = props => (
  <HowToApply
    id="how-to-apply"
    box1={
      <React.Fragment>
        <Heading4
          color={COLOR.BLACK}
          marginBottom={MARGIN.M16}
          isResponsive={false}
        >
          {props.title || data.title}
        </Heading4>
        <ListNumbered
          data={props.data || data.data}
          viewStyle="compact"
          separator="none"
          counterBgColor={COLOR.BLUE_DEEP}
        />
      </React.Fragment>
    }
    box2={
      <React.Fragment>
        <Heading4
          color={COLOR.BLACK}
          marginBottom={MARGIN.M16}
          isResponsive={false}
        >
          Eligibility
        </Heading4>
        <Strong
          css={`
            display: block;
            margin-bottom: ${MARGIN.M8};
          `}
        >
          You must:
        </Strong>
        <List>
          <ListItem>Be 18 years or over</ListItem>
          <ListItem>Be a permanent NZ resident</ListItem>
          <ListItem>Be earning a stable income</ListItem>
        </List>
      </React.Fragment>
    }
  />
);

export default HowToApplyEligibility;
