// @flow

import React, { Component } from 'react';
import { Heading3 } from '@latitude/heading';
import Table from '../Table/Table';
import Modal from '../Modal/Modal';
import './_fees-and-charges.scss';

class FeesAndCharges extends Component {
  state = {
    isModalOpen: false
  };

  handleModalRequestClose = () => {
    this.setState({ isModalOpen: false });
    window.location.hash = '';
  };

  handleLinkClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    this.setState({
      isModalOpen: true
    });
  };

  handleWindowHash = () => {
    this.setState({
      isModalOpen: window.location.hash === '#fees-charges'
    });
  };

  componentDidMount = () => {
    this.handleWindowHash();
    window.addEventListener('hashchange', this.handleWindowHash, false);
  };

  componentWillUnmount = () => {
    window.removeEventListener('hashchange', this.handleWindowHash);
  };

  render() {
    return (
      <div className={`fees-charges ${this.props.className}`}>
        <Heading3 className="fees-charges__title">Fees & charges</Heading3>
        <Table
          sectioned
          className="fees-charges__table"
          data={this.props.fees}
          boldColumns={[1]}
        />
        {this.props.hideLink && this.props.earlyTerminationText ? (
          <table className="table-link">
            <tbody>
              <tr>
                <td />
                <td>
                  <a onClick={this.handleLinkClick} role="presentation">
                    See how this can change over time
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        ) : null}
        <p className="fees-charges__table small-text text--grey text-center px-2 px-lg-3 my-0">
          {this.props.fees2}
        </p>

        <Modal
          isOpen={this.state.isModalOpen}
          onRequestClose={this.handleModalRequestClose}
          title="Early termination fees"
          content={
            <span
              dangerouslySetInnerHTML={{
                __html: this.props.earlyTerminationText
              }}
            />
          }
          ariaHideApp={this.state.isModalOpen}
        />
      </div>
    );
  }
}

export default FeesAndCharges;
