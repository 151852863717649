// @noflow
import React, { useEffect, useState, useContext } from 'react';
import { Box } from '@latitude/box';
import { Text } from '@latitude/text';
import { Link } from '@latitude/link';
import Modal from '../Modal/Modal';
import {
  ALIGN,
  MARGIN,
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT
} from '@latitude/core/utils/constants';
import { Heading5 } from '@latitude/heading';
import { CurrencyInput } from '@latitude/currency-input';
import ConfettiText from '../ConfettiText/ConfettiText';
import Input from '../Input/Input';
import SoftQuoteContext from '../../context/SoftQuoteContext';
import { SOFT_QUOTE_URL, RETRIEVE_URL } from '../../utils/softQuoteUtil';
import {
  REPAYMENT_PERIOD_NZ_OPTIONS,
  PURPOSE_NZ_OPTIONS,
  PURPOSE_ERROR_MESSAGE,
  PURPOSE_OTHER_ERROR_MESSAGE,
  AMOUNT_MIN_ERROR_MESSAGE,
  AMOUNT_MAX_ERROR_MESSAGE
} from './constants';
import {
  LOAN_PURPOSE,
  CL_DEFAULT_LOAN_TERM,
  PL_MINIMUM_AMOUNT,
  PL_MAXIMUM_AMOUNT
} from '../../utils/constants';
import {
  MainContent,
  FormFields,
  LoanPurposeField,
  BorrowAmountField,
  RepaymentPeriodField,
  StyledSelect,
  GetStartedButton,
  Footer
} from './EstimateRateWidget.styled';
import SvgInline from '@latitude/svg-inline';
interface Props {
  defaultPurpose?: string;
  defaultPurposeOther?: string;
  defaultLoanAmount?: number;
  defaultRepaymentPeriod?: number;
  trackProductId?: Array<string>;
}

const EstimateRateWidget = (props: Props) => {
  const {
    defaultPurpose,
    defaultPurposeOther,
    defaultLoanAmount,
    defaultRepaymentPeriod,
    trackProductId
  } = props;

  const [purpose, setPurpose] = useState(defaultPurpose);
  const [purposeOther, setPurposeOther] = useState(defaultPurposeOther);
  const [loanAmount, setLoanAmount] = useState(defaultLoanAmount);
  const [repaymentPeriod, setRepaymentPeriod] = useState(defaultRepaymentPeriod);
  const [loanPurposeMoreInfoModalOpen, setLoanPurposeMoreInfoModalOpen] = useState(false);

  /* Purpose error message state */
  const [hasPurposeError, setHasPurposeError] = useState(false);

  /* Loan Amount error message state */
  const [hasAmountError, setHasAmountError] = useState(false);
  const [amountErrorMessage, setAmountErrorMessage] = useState<React.ReactNode | string>('');

  /* soft quote redirect url context */
  const { applyUrl, setApplyUrl } = useContext(SoftQuoteContext);
  const updateRedirectUrl = () => {
    const purposeOtherText =
      purpose === LOAN_PURPOSE.OTHER ? `&other=${purposeOther}` : '';
    const url = `${SOFT_QUOTE_URL}?amount=${loanAmount}&purpose=${purpose}&period=${repaymentPeriod}${purposeOtherText}`;
    setApplyUrl(url);
  };

  useEffect(() => {
    updateRedirectUrl();
  }, [purpose, loanAmount, purposeOther, repaymentPeriod]);

  useEffect(() => {
    if (!loanAmount || loanAmount < PL_MINIMUM_AMOUNT) {
      setHasAmountError(true);
      setAmountErrorMessage(AMOUNT_MIN_ERROR_MESSAGE);
    } else if (loanAmount > PL_MAXIMUM_AMOUNT) {
      setHasAmountError(true);
      setAmountErrorMessage(AMOUNT_MAX_ERROR_MESSAGE);
    } else {
      setHasAmountError(false);
      setAmountErrorMessage('');
    }
  }, [loanAmount]);

  return (
    <Box id='estimate-rate-widget-box'>
      <MainContent>
        <Heading5
          align={ALIGN.CENTER}
          fontWeight={FONT_WEIGHT.BOLD}
          color={COLOR.BLACK}
        >
          Check your rate
        </Heading5>
        <Text align={ALIGN.CENTER} marginBottom={MARGIN.M16}>
          We’ll ask you a few quick questions about your circumstances to give
          you a personalised interest rate and repayment amount.
        </Text>

        <FormFields>
          <LoanPurposeField>
            <Box className="mb-4">
              <StyledSelect
                widget
                id="purpose"
                label="I need a loan for"
                color={COLOR.BLUE}
                values={PURPOSE_NZ_OPTIONS}
                selectedValue={purpose}
                onChange={e => {
                  setPurpose(e.target.value);
                  setHasPurposeError(
                    e.target.value === LOAN_PURPOSE.SELECT_PURPOSE
                  );
                }}
                showError={hasPurposeError}
                showErrorMessage={PURPOSE_ERROR_MESSAGE}
              />
              <Link
                href="#"
                noStyle
                onClick={(e)=> {
                  setLoanPurposeMoreInfoModalOpen(true);
                  e.preventDefault();
                }}
                trackEventData={{ location: 'Check your rate' }}
                style={{display: (purpose==='consolidation')?'flex':'none', alignItems: 'center', borderBottomStyle: 'none' }}
              >
                <SvgInline name="info-block" fillBlueBright style={{ marginRight: MARGIN.M4, width: '16px', height: '16px' }} />
                <Text marginTop={MARGIN.M4} marginBottom={MARGIN.M4} fontSize={FONT_SIZE.SMALL} className='text-underline'>
                  Additional payout fees may apply
                </Text>
              </Link>
            </Box>

            {purpose === LOAN_PURPOSE.OTHER && (
              <Box className="mb-4">
                <Input
                  widget
                  id="other-purpose"
                  label="Other loan purpose"
                  maxLength={20}
                  value={purposeOther}
                  onChange={e => {
                    setPurposeOther(e.target.value);
                  }}
                  type="text"
                  errorMessage={PURPOSE_OTHER_ERROR_MESSAGE}
                  showWidgetError={purposeOther === ''}
                />
              </Box>
            )}
          </LoanPurposeField>
          <BorrowAmountField>
            <Box className="mb-4">
              <CurrencyInput
                widget
                labelText="I'd like to borrow"
                name="currency-input-story"
                maxLength={10}
                prefix="$"
                value={loanAmount}
                onValueChange={e => {
                  setLoanAmount(e.value);
                }}
                showErrorMessage={amountErrorMessage}
                showError={hasAmountError}
              />
            </Box>
          </BorrowAmountField>
          <RepaymentPeriodField>
            <Box className="mb-4">
              <StyledSelect
                widget
                color={COLOR.BLUE}
                label="Over a period of"
                selectedValue={repaymentPeriod}
                values={REPAYMENT_PERIOD_NZ_OPTIONS}
                onChange={e => {
                  setRepaymentPeriod(e.target.value);
                }}
              />
            </Box>
          </RepaymentPeriodField>
        </FormFields>
        <GetStartedButton
          id="getStartedButton"
          className="mx-auto"
          button="primary"
          href={applyUrl}
          target="_self"
          trackId="estimate-rate-get-started"
          trackEventData={{
            category: 'cta',
            action: 'quote-link',
            location: 'Check your rate'
          }}
          trackProductId={trackProductId}
        >
          Get started
        </GetStartedButton>
        <Box marginTop={MARGIN.M16}>
          <ConfettiText
            text="This won't affect your Credit Score"
            backgroundColor={COLOR.TRANSPARENT}
          />
        </Box>
      </MainContent>

      <Footer>
        <Text align={ALIGN.CENTER}>
          Already have a rate?&nbsp;
          <Link
            href={RETRIEVE_URL}
            target="_self"
            data-trackid='check-your-rate-retrieve-your-rate'
            trackEventData={{
              category: 'cta',
              action: 'quote-retrieval-link',
              location: 'Check your rate'
            }}
            trackProductId={trackProductId}
          >
            Retrieve your rate
          </Link>
        </Text>
      </Footer>
      <Modal
        className="more-info-modal"
        isOpen={loanPurposeMoreInfoModalOpen}
        onRequestClose={()=>{setLoanPurposeMoreInfoModalOpen(false)}}
        title={'Additional payout fees may apply'}
        content={<div dangerouslySetInnerHTML={{
           __html: 'When applying for a debt consolidation loan, you may be charged fees by the other financier(s) when we repay your debts. Please include these fees in your requested loan amount.</p><p>When you apply for your loan, you will be asked to accept these fees in order to acheive your objectives.'
        }} />}
        ariaHideApp={loanPurposeMoreInfoModalOpen}
      />
    </Box>
  );
};

const defaultProps = {
  defaultPurpose: LOAN_PURPOSE.SELECT_PURPOSE,
  defaultPurposeOther: '',
  defaultLoanAmount: PL_MINIMUM_AMOUNT,
  defaultRepaymentPeriod: CL_DEFAULT_LOAN_TERM,
  trackProductId: []
};

EstimateRateWidget.defaultProps = defaultProps;

export default EstimateRateWidget;
